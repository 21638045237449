<template>
  <LayoutNew>
    <h1 class="text-center">User Management</h1>
    <MDBLoading v-model="loadingSpinner" class="user-management-loader" loadingText="" icon="spinner-grow" />
    <MDBCard id="UploadFile" class="mb-2">
      <!-- header -->
      <MDBCardHeader class="d-flex justify-content-between align-items-center p-4">
        <router-link :to="{ name: 'AddUser' }" custom v-slot="{ href, navigate }">
          <MDBBtn tag="a" color="primary" @click="navigate" :href="href" class="form-icon-trailing" size="sm">
            <MDBIcon iconStyle="fas" icon="user-plus" class="me-2 trailing" />
            Add
          </MDBBtn>
        </router-link>
        <div class="d-inline">
          <UserManagementSearchNew :searchType="'userManagement'" @searchResult="userManagementList = $event" />
        </div>
      </MDBCardHeader>
      <!-- table -->
      <MDBCardBody class="overflow-auto">
        <MDBTable striped hover class="align-middle">
          <thead>
            <tr class="table-dark">
              <th>Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="userManagementList.data.length === 0 && !loadingSpinner">
              <td colspan="3" class="text-center">No user found.</td>
            </tr>
            <template v-else v-for="(item, i) in userManagementList.data" :key="i">
              <UserManagementTableRowNew :item="item" @onAction="getUsers" />
            </template>
          </tbody>
        </MDBTable>
      </MDBCardBody>
      <MDBCardFooter class="pb-0">
        <Paginate :pageType="'userManagement'" @paginationData="userManagementList = $event"
          @paginationLoading="loadingSpinner = $event" />
      </MDBCardFooter>
    </MDBCard>
    <DeductionsToast />
  </LayoutNew>
</template>

<script setup>
import {
  MDBLoading,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBBtn,
  MDBIcon,
  MDBTable,
} from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew.vue";
import { useTitle } from "@vueuse/core";
import DeductionsToast from "../../components/Deductions/DeductionsToast.vue";
import { useToastStore } from "../../store/toastStore";
import { onMounted, ref } from "vue";
import axios from "axios";
import UserManagementSearchNew from "./UserManagementSearchNew.vue";
import UserManagementTableRowNew from "./UserManagementTableRowNew.vue";
import Paginate from "../../components/Pagination/CSDPagination.vue";

useTitle("User Management | CreatorShield");

const toastStore = useToastStore();

const userManagementList = ref({
  data: [],
  pagination: {},
});

const loadingSpinner = ref(false);

const getUsers = async () => {
  loadingSpinner.value = true;
  await axios
    .get("api/users")
    .then(function (response) {
      userManagementList.value = response.data;
      loadingSpinner.value = false;
    })
    .catch(function () {
      toastStore.toastObject = {
        ...toastStore.toastObject,
        ...toastStore.toastStatus.fail,
      };
      loadingSpinner.value = false;
    });
};

onMounted(() => {
  getUsers();
});
</script>

<style scoped>
.btn-primary {
  background-color: var(--primary);
}

.btn-outline-primary {
  border-color: var(--accent);
  color: var(--accent);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.table-dark {
  --mdb-table-bg: var(--accent);
  border-color: var(--accent);
}
</style>

<style>
.user-management-loader {
  color: var(--accent);
}
</style>
