<template>
  <MDBInput
    inputGroup="sm"
    :formOutline="false"
    v-model="searchString"
    aria-describedby="search-addon"
    aria-label="Search"
    placeholder="Enter to Search"
    @keydown.enter="handleSearch"
  >
    <span class="input-group-text" @click="handleSearch"><em class="fas fa-search" /></span>
  </MDBInput>
</template>

<script setup>
import { MDBInput } from "mdb-vue-ui-kit";
import axios from "axios";
import { ref, computed, defineProps, defineEmits } from "vue";

const props = defineProps(["searchType"]);
const emit = defineEmits(["change", "delete"]);
const searchString = ref("");
const assetData = ref("");
const handleSearch = () => {
  axios
    .get(getUrl.value, {
      PageNumber: 1,
      params: { SearchString: searchString.value },
    })
    .then(function (response) {
      assetData.value = response.data;
      emit("searchResult", assetData.value);
    });
};

const getUrl = computed(() => {
  if (props.searchType == "assets") {
    return "api/assets/List";
  } else if (props.searchType == "assetLabel") {
    return "api/assetlabels/List";
  } else if (props.searchType == "userManagement") {
    return "api/users";
  } else if (props.searchType == "notifManagement") {
    return "api/creatorvideoupdates/list";
  }

  return "";
});
</script>
