<template>
  <tr>
    <td>{{ item.firstName }} {{ item.lastName }}</td>
    <td>{{ item.email }}</td>
    <td>
      <!-- view dashboard -->
      <MDBTooltip v-if="item.userTypeId != 1" v-model="tooltipViewDashboard" boundary="scrollParent">
        <template #reference>
          <button type="button" outline="primary" @click.stop="impersonateUser(item)" size="sm" floating
            class="btn btn-sm btn-outline-primary btn-floating me-2 mb-2">
            <MDBIcon iconStyle="fas" icon="chart-bar" />
            <MDBBadge badge="danger" size="xs" class="border border-light rounded-circle position-absolute" dot>
            </MDBBadge>
          </button>
        </template>
        <template #tip> View Dashboard </template>
      </MDBTooltip>
      <!-- edit information -->
      <router-link :to="{ name: 'UpdateUser', params: { id: item.id } }" custom v-slot="{ href, navigate }">
        <MDBTooltip v-model="tooltipEdit">
          <template #reference>
            <MDBBtn tag="a" outline="primary" @click.stop="navigate" :href="href" size="sm" floating class="me-2 mb-2">
              <MDBIcon iconStyle="fas" icon="pen" />
            </MDBBtn>
          </template>
          <template #tip> Edit Information </template>
        </MDBTooltip>
      </router-link>
      <!-- view adjustments -->
      <router-link :to="{
        name: 'AdjustmentsNew',
        params: {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
        },
      }" custom v-slot="{ href, navigate }">
        <MDBTooltip v-model="tooltipAdjustment">
          <template #reference>
            <MDBBtn tag="a" outline="primary" @click.stop="sendParams(item, navigate)" :href="href" size="sm" floating
              class="me-2 mb-2">
              <MDBIcon iconStyle="fas" icon="hand-holding-usd" />
            </MDBBtn>
          </template>
          <template #tip> View Adjustments </template>
        </MDBTooltip>
      </router-link>
      <!-- update partner percentage -->
      <router-link :to="{
        name: 'PartnerPercentage',
        params: {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
        },
      }" custom v-slot="{ href, navigate }">
        <MDBTooltip v-model="tooltipPartnerPercentage">
          <template #reference>
            <MDBBtn tag="a" outline="primary" @click.stop="sendParams(item, navigate)" :href="href" size="sm" floating
              class="me-2 mb-2">
              <MDBIcon iconStyle="fas" icon="percent" />
            </MDBBtn>
          </template>
          <template #tip> Update Partner Percentage </template>
        </MDBTooltip>
      </router-link>
      <!-- view monthly report -->
      <router-link :to="{
        name: 'MonthlyReport',
        params: {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
        },
      }" custom v-slot="{ href, navigate }">
        <MDBTooltip v-model="tooltipMonthlyReport">
          <template #reference>
            <MDBBtn tag="a" outline="primary" @click.stop="sendParams(item, navigate)" :href="href" size="sm" floating
              class="me-2 mb-2">
              <MDBIcon iconStyle="fas" icon="file-alt" />
            </MDBBtn>
          </template>
          <template #tip> View Monthly Report </template>
        </MDBTooltip>
      </router-link>
      <!-- reset password -->
      <MDBTooltip v-model="tooltipResetPassword">
        <template #reference>
          <MDBBtn outline="primary" @click.stop="clickAction(item, 'resetPassword')" size="sm" floating
            class="me-2 mb-2">
            <MDBIcon iconStyle="fas" icon="envelope" />
          </MDBBtn>
        </template>
        <template #tip> Reset Password </template>
      </MDBTooltip>
      <!-- delete account -->
      <MDBTooltip v-model="tooltipDelete">
        <template #reference>
          <MDBBtn outline="primary" @click.stop="clickAction(item, 'delete')" size="sm" floating class="me-2 mb-2">
            <MDBIcon iconStyle="fas" icon="trash" />
          </MDBBtn>
        </template>
        <template #tip> Delete Account </template>
      </MDBTooltip>
    </td>
    <!-- delete account modal -->
    <MDBModal id="deleteModal" tabindex="-1" labelledby="deleteModalLabel" v-model="deleteModal" class="ps-0">
      <MDBModalHeader>
        <MDBModalTitle id="deleteModalLabel"> Delete Account </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>Are you sure you want to delete this account?</MDBModalBody>
      <MDBModalFooter class="gap-2 py-3">
        <MDBBtn @click="deleteModal = false" size="sm"> Close </MDBBtn>
        <MDBBtn color="danger" @click="deleteUser(modalItem)" size="sm">
          Confirm
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <!-- reset password modal -->
    <MDBModal id="resetPasswordModal" tabindex="1" labelledby="resetPasswordModalLabel" v-model="resetPasswordModal" class="ps-0">
      <MDBModalHeader>
        <MDBModalTitle id="resetPasswordModalLabel">
          Reset Password
        </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        Are you sure you want to reset this account's password?
      </MDBModalBody>
      <MDBModalFooter class="py-3 gap-2">
        <MDBBtn size="sm" @click="resetPasswordModal = false"> Close </MDBBtn>
        <MDBBtn color="primary" size="sm" @click="resetPassword(modalItem)">
          Confirm
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  </tr>
</template>

<script setup>
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTooltip,
  MDBBadge,
} from "mdb-vue-ui-kit";
import { ref, defineProps, defineEmits } from "vue";
import { useToastStore } from "../../store/toastStore";
import axios from "axios";
import { useSendParamsDetails } from "../../store/sendParamsDetails";
import { useImpersonateStore } from "../../store/impersonateStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

defineProps({
  item: Object,
});

const emit = defineEmits(["onAction"]);
const toastStore = useToastStore();
const modalItem = ref("");
const deleteModal = ref(false);
const resetPasswordModal = ref(false);
const tooltipEdit = ref(false);
const tooltipAdjustment = ref(false);
const tooltipPartnerPercentage = ref(false);
const tooltipMonthlyReport = ref(false);
const tooltipResetPassword = ref(false);
const tooltipDelete = ref(false);
const tooltipViewDashboard = ref(false);

const sendParamsDetails = useSendParamsDetails();
const sendParams = (item, navigate) => {
  sendParamsDetails.userDetail = {
    firstName: item.firstName,
    lastName: item.lastName,
  };
  navigate();
};

const clickAction = (item, type) => {
  modalItem.value = item;
  if (type === "delete") {
    deleteModal.value = true;
  } else if (type === "resetPassword") {
    resetPasswordModal.value = true;
  }
};

const deleteUser = (data) => {
  const toastSuccessDelete = {
    ...toastStore.toastStatus.success,
    ...{
      message: "Successfully deleted!",
    },
  };
  axios
    .patch("api/users/" + data.id + "/delete")
    .then(function (response) {
      if (response.status == 200) {
        emit("onAction");
        toastStore.toastObject = {
          ...toastStore.toastObject,
          ...toastSuccessDelete,
        };
        deleteModal.value = false;
      }
    })
    .catch(function () {
      toastStore.toastObject = {
        ...toastStore.toastObject,
        ...toastStore.toastStatus.fail,
      };
    });
};

const resetPassword = (data) => {
  let formData = new FormData();
  formData.append("email", data.email);
  formData.append("userId", data.id);
  axios
    .post("api/users/resetuser", formData)
    .then(function (response) {
      if (response.status == 200) {
        emit("onAction");
        toastStore.toastObject = {
          ...toastStore.toastObject,
          ...toastStore.toastStatus.success,
        };
        resetPasswordModal.value = false;
      }
    })
    .catch(function () {
      toastStore.toastObject = {
        ...toastStore.toastObject,
        ...toastStore.toastStatus.fail,
      };
    });
};

const impersonateStore = useImpersonateStore();
const { isImpersonating, userImpersonateDetail } =
  storeToRefs(impersonateStore);
const router = useRouter();

const impersonateUser = (userDetail) => {
  userImpersonateDetail.value = userDetail;
  isImpersonating.value = true;
  router.push("/Dashboard");
};
</script>

<style scoped>
.btn-primary {
  background-color: var(--primary);
}

.btn-outline-primary {
  border-color: var(--accent);
  color: var(--accent);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
</style>

<style>
.tooltip-inner {
  font-family: "Poppins", sans-serif !important;
  background-color: var(--primary);
  font-size: 12px;
  padding: 3px 12px;
}

.badge-dot {
  background-color: var(--primary);
}
</style>
